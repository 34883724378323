<template>
	<div class="host-filter">
		<h3>Filter</h3>
		<div class="filter-item item-spanned">
			<div class="control">
				<input id="chkFilterAcceptingContracts" type="checkbox" v-model="acceptingContracts" />
				<label for="chkFilterAcceptingContracts">Accepting Contracts</label>
			</div>
			<div class="control">
				<input id="chkFilterHideInactive" type="checkbox" v-model="showInactive" />
				<label for="chkFilterHideInactive">Show Inactive</label>
			</div>
			<div class="control">
				<input id="chkHideOffline" type="checkbox" v-model="hideUntested" />
				<label for="chkHideOffline">Only Benchmarked</label>
			</div>
		</div>
		<div class="filter-item item-spanned">
			<div class="control">
				<label>Sort</label>
				<select v-model="sort">
					<option value="uptime">Estimated Uptime</option>
					<option value="age">Age</option>
					<optgroup label="Storage">
						<option value="remaining_storage">Remaining Storage</option>
						<option value="used_storage">Used Storage</option>
						<option value="total_storage">Total Storage</option>
						<option value="utilization">Utilization</option>
					</optgroup>
					<optgroup label="Speed">
						<option value="upload_speed">Upload Speed</option>
						<option value="download_speed">Download Speed</option>
					</optgroup>
					<optgroup label="Pricing">
						<option value="contract_price">Contract Price</option>
						<option value="storage_price">Storage Price</option>
						<option value="upload_price">Upload Price</option>
						<option value="download_price">Download Price</option>
					</optgroup>
				</select>
			</div>
			<div class="control">
				<label>&nbsp;</label>
				<select v-model="sortDirection">
					<option value="desc">Descending</option>
					<option value="asc">Ascending</option>
				</select>
			</div>
		</div>
		<div class="filter-item">
			<label class="filter-label">Max Contract Price</label>
			<currency-input v-model:value="contractPrice" />
		</div>
		<div class="filter-item">
			<label class="filter-label">Max Storage Price</label>
			<currency-input v-model:value="storagePrice" />
		</div>
		<div class="filter-item">
			<label class="filter-label">Max Upload Price</label>
			<currency-input v-model:value="uploadPrice" />
		</div>
		<div class="filter-item">
			<label class="filter-label">Max Download Price</label>
			<currency-input v-model:value="downloadPrice" />
		</div>
	</div>
</template>

<script>
import BigNumber from 'bignumber.js';

import CurrencyInput from '@/components/CurrencyInput';

let debounce;

export default {
	components: { CurrencyInput },
	props: {
		filter: Object,
		network: String,
	},
	data() {
		return {
			storagePrice: new BigNumber(0),
			uploadPrice: new BigNumber(0),
			downloadPrice: new BigNumber(0),
			contractPrice: new BigNumber(0),
			showInactive: false,
			hideUntested: false,
			acceptingContracts: false,
			sort: 'download_speed',
			sortDirection: 'desc',
		};
	},
	methods: {
		onUpdateFilter() {
			try {
				const filter = {
					sort: this.sort,
					dir: this.sortDirection,
					showinactive: this.showInactive,
				};

				if (this.acceptingContracts)
					filter.acceptcontracts = true;

				if (this.hideUntested)
					filter.benchmarked = true;

				if (this.storagePrice.gt(0))
					filter.maxstorageprice = this.storagePrice.div(1e12).div(4320).toFixed(0);

				if (this.uploadPrice.gt(0))
					filter.maxuploadprice = this.uploadPrice.div(1e12).toFixed(0);

				if (this.downloadPrice.gt(0))
					filter.maxdownloadprice = this.downloadPrice.div(1e12).toFixed(0);

				if (this.contractPrice.gt(0))
					filter.maxcontractprice = this.contractPrice.toFixed(0);

				this.$emit('update:filter', filter);
			} catch (ex) {
				console.error('HostFilter.onUpdateFilter');
			}
		}
	},
	watch: {
		$data: {
			deep: true,
			handler: function() {
				clearTimeout(debounce);
				debounce = setTimeout(this.onUpdateFilter, 400);
			},
		},
	}
};
</script>

<style lang="stylus" scoped>
.host-filter {
	display: grid;
	padding: 15px;
	margin-bottom: 15px;
	grid-gap: 15px;
	grid-template-columns: repeat(2, minmax(0, 1fr));
	align-items: center;
	background: bg-dark-accent;
	border-radius: 15px;
	box-shadow: 1px 2px 5px 1px rgba(0, 0, 0, 0.2);

	@media screen and (min-width: 767px) {
		position: sticky;
		top: 15px;
		grid-template-columns: minmax(0, 1fr);
	}

	h3 {
		margin: 0;
		padding: 0;
		text-align: center;
		color:rgba(255, 255, 255, 0.54);
	}
}

.filter-item {
	&.item-spanned {
		grid-column: 1 / -1;
	}

	.control input {
		text-align: right;
	}

	.split-control {
		display: grid;
		grid-template-columns: minmax(0, 1fr) auto;
		align-items: center;
		grid-gap: 5px;

		label {
			margin: 0;
			padding: 0;
			color: rgba(255,255,255,0.54);
		}
	}
}
</style>