<template>
	<div id="nav"></div>
	<router-view v-slot="{ Component }">
		<transition name="fade" mode="out-in" appear>
			<component :is="Component" />
		</transition>
	</router-view>
</template>

<style lang="stylus">
@require "./styles/global";

#app {
	width: 100%;
	height: 100%;

	@media screen and (min-width: 767px) {
		display: grid;
		grid-template-columns: auto minmax(0, 1fr);
	}
}
</style>
