async function sendJSONRequest(url, method, data) {
	const r = await fetch(url, {
			method: method,
			mode: 'cors',
			cache: 'no-cache',
			body: data ? JSON.stringify(data) : null
		}),
		resp = await r.json();

	return { statusCode: r.status >= 200 && r.status < 300 ? 200 : r.status, body: resp };
}

export async function getSiaNetworkMetrics(proto = 'rhp3') {
	const resp = await sendJSONRequest(`https://api.siacentral.com/v2/hosts/network/metrics?protocol=${proto}`, 'GET', null);

	if (resp.statusCode !== 200)
		throw new Error(resp.body.message);

	return resp.body;
}

export async function getSiaHosts(filter = {}) {
	const params = new URLSearchParams();
	for (const k in filter)
		params.append(k, filter[k]);

	const resp = await sendJSONRequest(`https://api.siacentral.com/v2/hosts/list?${params.toString()}`, 'GET', null);
	if (resp.statusCode !== 200)
		throw new Error(resp.body.message);

	return resp.body;
}

export async function getSiaHeight() {
	const resp = await sendJSONRequest('https://api.siacentral.com/v2/explorer/block', 'GET', null);

	if (resp.statusCode !== 200)
		throw new Error(resp.body.message);

	return resp.body.height;
}

export async function getZenNetworkMetrics(proto = 'rhp3') {
	const resp = await sendJSONRequest(`https://api.siacentral.com/v2/zen/hosts/network/metrics?protocol=${proto}`, 'GET', null);

	if (resp.statusCode !== 200)
		throw new Error(resp.body.message);

	return resp.body;
}

export async function getZenHosts(filter = {}) {
	const params = new URLSearchParams();
	for (const k in filter)
		params.append(k, filter[k]);

	const resp = await sendJSONRequest(`https://api.siacentral.com/v2/zen/hosts/list?${params.toString()}`, 'GET', null);
	if (resp.statusCode !== 200)
		throw new Error(resp.body.message);

	return resp.body;
}

export async function getZenHeight() {
	const resp = await sendJSONRequest('https://api.siacentral.com/v2/zen/explorer/block', 'GET', null);

	if (resp.statusCode !== 200)
		throw new Error(resp.body.message);

	return resp.body.height;
}

export async function getScPrimeNetworkMetrics(proto = 'rhp3') {
	const resp = await sendJSONRequest(`https://api.siacentral.com/v2/scprime/hosts/network/metrics?protocol=${proto}`, 'GET', null);

	if (resp.statusCode !== 200)
		throw new Error(resp.body.message);

	return resp.body;
}

export async function getScPrimeHosts(filter = {}) {
	const params = new URLSearchParams();
	for (const k in filter)
		params.append(k, filter[k]);

	const resp = await sendJSONRequest(`https://api.siacentral.com/v2/scprime/hosts/list?${params.toString()}`, 'GET', null);
	if (resp.statusCode !== 200)
		throw new Error(resp.body.message);

	return resp.body;
}

export async function getExchangeRate() {
	const resp = await sendJSONRequest('https://api.siacentral.com/v2/market/exchange-rate?currencies=sc,scp', 'GET', null);

	if (resp.statusCode !== 200)
		throw new Error(resp.body.message);

	return resp.body.rates;
}

export async function getScPrimeHeight() {
	const resp = await sendJSONRequest('https://api.siacentral.com/v2/scprime/explorer/block', 'GET', null);

	if (resp.statusCode !== 200)
		throw new Error(resp.body.message);

	return resp.body.height;
}