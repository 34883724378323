<template>
	<div class="host-wrapper">
		<div :class="{ host: true, 'host-inactive': !active, 'host-warning': active && !benchmarked }">
			<div class="host-header">
				<div class="host-meta">{{ netaddress }} <button class="copy-pubkey" @click.prevent="copyToClipboard"><icon :icon="['fad', 'copy']" /></button></div>
				<div class="host-version">{{ version }}</div>
			</div>
			<div class="host-info">
				<div class="host-detail">
					<div>{{ acceptingContracts }}</div>
					<div class="host-label">Accepts Contracts</div>
				</div>
				<div class="host-detail">
					<div v-html="lastSeen" />
					<div class="host-label">Last Seen</div>
				</div>
				<div class="host-detail">
					<div>{{ uptime }}<span class="currency-display">%</span></div>
					<div class="host-label">Uptime</div>
				</div>
				<div class="host-detail">
					<div v-html="firstSeen" />
					<div class="host-label">Age</div>
				</div>
				<div class="host-detail">
					<currency-display :value="contractPrice" />
					<div class="host-label">Contract Price</div>
				</div>
				<div class="host-detail">
					<currency-display :value="storagePrice" />
					<div class="host-label">Storage Price</div>
				</div>
				<div class="host-detail">
					<currency-display :value="uploadPrice" />
					<div class="host-label">Upload Price</div>
				</div>
				<div class="host-detail">
					<currency-display :value="downloadPrice" />
					<div class="host-label">Download Price</div>
				</div>
			</div>
			<div class="host-benchmarks">
				<benchmark-results :benchmark="benchmark" />
			</div>
			<div class="storage-usage" v-html="storageUsage" />
		</div>
	</div>
</template>

<script>
import BigNumber from 'bignumber.js';
import { formatByteString, formatAge } from '@/utils/format';
import CurrencyDisplay from './CurrencyDisplay.vue';
import BenchmarkResults from './BenchmarkResults.vue';

export default {
	components: {
		BenchmarkResults,
		CurrencyDisplay,
	},
	props: {
		host: Object,
		protocol: String,
		network: String,
	},
	computed: {
		version() {
			if (typeof this.host?.settings?.make === 'string' && this.host?.settings?.make.length !== 0)
				return `${this.host.settings.make} ${this.host.settings.model}`;

			if (typeof this.host?.settings?.version === 'string' && this.host?.settings?.version.length !== 0)
				return `${this.network.toLowerCase() === 'scprime' ? 'ScPrime' : 'Sia'} v${this.host.settings.version}`;

			return 'unknown';
		},
		active() {
			if (!this.host.last_success_scan || this.host.last_success_scan === '0001-01-01T00:00:00Z')
				return false;

			return (new Date() - new Date(this.host.last_success_scan)) < 8.64e7 * 3;
		},
		netaddress() {
			return typeof this.host.public_key === 'string' ? this.host.public_key.substr(8, 8) : 'unknown';
		},
		acceptingContracts() {
			return this.host?.settings?.accepting_contracts ? 'Yes' : 'No';
		},
		firstSeen() {
			const formatted = formatAge(new Date(this.host.first_seen_timestamp));

			return `${formatted.map(({ value, label }) => `${value} <span class="currency-display">${label}</span>`).join(' ')}`;
		},
		lastSeen() {
			if (!this.host.last_success_scan || this.host.last_success_scan === '0001-01-01T00:00:00Z')
				return 'Never';

			if (new Date() - new Date(this.host.last_success_scan) <= 8.64e7) // today
				return 'Today';

			const formatted = formatAge(new Date(this.host.last_success_scan));

			return formatted.map(({ value, label }) => `${value} <span class="currency-display">${label}</span>`).join(' ') + ' <span class="currency-display">ago</span>';
		},
		benchmarked() {
			if (!this.benchmark?.last_successful || this.benchmark.last_successful === '0001-01-01T00:00:00Z')
				return false;

			return (new Date() - new Date(this.benchmark.last_successful)) < 8.64e7 * 10;
		},
		uptime() {
			return `${Math.round(this.host.estimated_uptime * 100) / 100}`;
		},
		benchmark() {
			return (this.protocol === 'rhp2' ? this.host?.benchmark_rhp2 : this.host?.benchmark) || {};
		},
		storagePrice() {
			return new BigNumber(this.host?.settings?.storage_price || '0').times('1e12').times(4320);
		},
		uploadPrice() {
			return new BigNumber(this.host?.settings?.upload_price || '0').times('1e12');
		},
		downloadPrice() {
			return new BigNumber(this.host?.settings?.download_price || '0').times('1e12');
		},
		contractPrice() {
			return new BigNumber(this.host?.settings?.contract_price || '0');
		},
		usedPct() {
			return 50;
		},
		availableStorage() {
			const { value, label } = formatByteString(this.host?.settings?.remaining_storage || 0, 'decimal');

			return `${value} <span class="currency-display">${label.toUpperCase()} Free</span>`;
		},
		totalStorage() {
			const { value, label } = formatByteString(this.host?.settings?.total_storage || 0, 'decimal');

			return `${value} <span class="currency-display">${label.toUpperCase()} Total</span>`;
		},
		storageUsage() {
			let utilization = 0;
			if (this.host?.settings?.total_storage)
				utilization = Math.ceil(1e4 - ((this.host?.settings?.remaining_storage || 0) / (this.host?.settings?.total_storage) * 1e4)) / 100;
			return `${this.availableStorage} / ${this.totalStorage} (${utilization}% utilized)`;
		},
		troubleshootURL() {
			return `https://troubleshoot.siacentral.com/results/${this.network}/${encodeURIComponent(this.netaddress)}`;
		}
	},
	methods: {
		copyToClipboard() {
			try {
				navigator.clipboard.writeText(this.host.public_key);
			} catch (ex) {
				console.error('HostItem.copyToClipboard');
			}
		},
		onTroubleshoot() {
			try {

			} catch (ex) {
				console.error('HostItem.onTroubleshoot');
			}
		}
	}
};
</script>

<style lang="stylus" scoped>
.host-meta {
	display: flex;
    align-items: center;
    justify-content: center;
}

.copy-pubkey {
	background: transparent;
    border: none;
    outline: none;
    color: rgba(103, 103, 103, 84%);

	&:hover {
		color: rgba(103, 103, 103, 100%);
		cursor: pointer;
	}
}

.host {
	position: relative;
	display: grid;
	background: bg-dark-accent;
	padding: 15px;
	margin-bottom: 10px;
	grid-gap: 15px 30px;
	align-items: center;
	font-size: 1.2rem;
	border: 3px solid #278c63;
	border-radius: 15px;

	@media screen and (min-width: 767px) {
		grid-template-columns: minmax(0, 1fr) auto;
	}

	&.host-warning {
		border-color: #8a8038;
	}

	&.host-inactive {
		border-color: #622f2f;
	}

	.host-header {
		display: grid;
		grid-template-columns: repeat(2, auto);
		justify-content: space-between;
		align-items: center;
		color: rgba(255, 255, 255, 0.54);
		font-size: 1.1em;
		grid-column: 1 / -1;
		overflow: hidden;
		text-overflow: ellipsis;

		.host-version {
			font-size: 0.8em;
			background: bg-dark;
			padding: 4px 8px;
			border-radius: 6px;
			color: rgba(255, 255, 255, 0.3);
		}
	}

	.host-info {
		display: grid;
		grid-template-columns: repeat(2, auto);
		grid-gap: 15px;
		justify-content: space-between;
		margin-bottom: 5px;

		@media screen and (min-width: 767px) {
			grid-template-columns: repeat(4, auto);
		}

		.host-detail {
			text-align: right;
		}

		.host-label {
			text-align: right;
			color: primary;
			font-size: 0.8em;
		}
	}

	.storage-usage {
		text-align: center;
		grid-column: 1 / -1;
	}
}

.external-links {
	grid-column: 1/-1;
	text-align: right;

	a, button {
		color: rgba(255, 255, 255, 0.54);
		cursor: pointer;
		background: none;
		outline: none;
		border: none;
		font-size: 0.8rem;
		text-decoration: none;
	}

	a {
		color: #b8a663;
		margin-right: 5px;
	}
}
</style>