import { createStore } from 'vuex';
import { getExchangeRate } from '@/utils/api';

const store = createStore({
	state: {
		exchangeRate: { sc: { bch: '0.000010588578', btc: '0.0000001716', cad: '0.0035028708', cny: '0.0177345168', eth: '0.0000058545497868', eur: '0.00227473818', gbp: '0.002027028432', jpy: '0.2820985596', ltc: '0.0000459513912', rub: '0.2062218444', scp: '0.0422660098522167', sf: '0.0000003432', usd: '0.002679528852' }, scp: { bch: '0.0002505223', btc: '0.00000406', cad: '0.08287678', cny: '0.41959288', eth: '0.00013851673738', eur: '0.053819563', gbp: '0.0479588312', jpy: '6.67435986', ltc: '0.00108719492', rub: '4.87914154', sc: '23.6596736596736597', sf: '0.00000812', usd: '0.0633967782' } },
		displayCurrency: 'usd',
		network: 'sia',
		filter: {}
	},
	mutations: {
		setNetwork(state, network) {
			state.network = network;
		},
		setExchangeRate(state, rates) {
			state.exchangeRate = rates;
		}
	},
	actions: {
		setNetwork({ commit }, network) {
			commit('setNetwork', network);
		},
		setExchangeRate({ commit }, rates) {
			commit('setExchangeRate', rates);
		}
	},
	modules: {
	},
});

async function refreshExchangeRates() {
	const rates = await getExchangeRate();

	store.dispatch('setExchangeRate', rates);
}

refreshExchangeRates();

export default store;