<template>
	<div class="stat-panel hosts-panel">
		<div class="stat-label">Active Hosts</div>
		<div class="stat-primary">{{ activeHosts }}</div>
		<div class="stat-secondary">of {{ totalHosts }}</div>
	</div>
	<div class="stat-panel usage-panel">
		<div class="stat-label">Storage Usage</div>
		<div class="stat-primary" v-html="usedStorage" />
		<div class="stat-secondary" v-html="totalStorage" />
	</div>
	<div class="stat-panel usage-panel" v-if="network !== 'scprime'">
		<div class="stat-label">Registry Usage</div>
		<div class="stat-primary" v-html="usedReg" />
		<div class="stat-secondary" v-html="totalReg" />
	</div>
	<div :class="{ 'stat-panel': true, 'pricing-panel': true, 'hide-registry': network === 'scprime' }">
		<div class="stat-label">Average Pricing</div>
		<div class="sub">
			<div class="stat-sub">
				<currency-display :value="avgContractPrice" />
				<div class="sub-label">Contract Price</div>
			</div>
			<div class="stat-sub">
				<currency-display :value="avgStoragePrice" />
				<div class="sub-label">Storage Price</div>
			</div>
			<div class="stat-sub">
				<currency-display :value="avgUploadPrice" />
				<div class="sub-label">Upload Price</div>
			</div>
			<div class="stat-sub">
				<currency-display :value="avgDownloadPrice" />
				<div class="sub-label">Download Price</div>
			</div>
		</div>
	</div>
</template>

<script>
import BigNumber from 'bignumber.js';
import { formatNumber, formatByteString } from '@/utils/format';
import CurrencyDisplay from './CurrencyDisplay.vue';

export default {
	components: { CurrencyDisplay },
	props: {
		totals: Object,
		averages: Object,
		network: String,
	},
	computed: {
		activeHosts() {
			return formatNumber(this.totals?.active_hosts || 0);
		},
		totalHosts() {
			return formatNumber(this.totals?.total_hosts || 0);
		},
		avgContractPrice() {
			return new BigNumber(this.averages?.settings?.contract_price || '0');
		},
		avgStoragePrice() {
			return new BigNumber(this.averages?.settings?.storage_price || '0').times(1e12).times(4320);
		},
		avgUploadPrice() {
			return new BigNumber(this.averages?.settings?.upload_price || '0').times(1e12);
		},
		avgDownloadPrice() {
			return new BigNumber(this.averages?.settings?.download_price || '0').times(1e12);
		},
		usedStorage() {
			const { value, label } = formatByteString((this.totals?.total_storage || 0) - (this.totals?.remaining_storage || 0), 'decimal');

			return `${value} <span class="currency-display">${label.toUpperCase()}</span>`;
		},
		totalStorage() {
			const { value, label } = formatByteString(this.totals?.total_storage || 0, 'decimal');

			return `${value} <span class="currency-display">${label.toUpperCase()} Total</span>`;
		},
		usedReg() {
			return `${formatNumber((this.totals?.total_registry_entries || 0) - (this.totals?.remaining_registry_entries || 0), 0)} <span class="currency-display">used entries</span>`;
		},
		totalReg() {
			return `${formatNumber(this.totals?.total_registry_entries || 0, 0)} <span class="currency-display">total</span>`;
		},
		storageUsage() {
			const utilization = Math.ceil(1e4 - ((this.host?.settings?.remaining_storage || 0) / (this.host?.settings?.total_storage || 1) * 1e4)) / 100;

			return `${this.availableStorage} / ${this.totalStorage} (${utilization}% utilized)`;
		}
	}
};
</script>

<style lang="stylus" scoped>
.stat-panel {
	height: 100%;
	padding: 15px;
	background: #25272a;
	border-radius: 15px;
	text-align: center;
	white-space: nowrap;

	.stat-label {
		color: rgba(255, 255, 255, 0.54);
		margin-bottom: 5px;
	}
}

.hosts-panel, .usage-panel {
	.stat-primary {
		font-size: 2em;
		color: #19cf86;
		margin-bottom: 2px;
	}

	.stat-secondary {
		color: rgba(255, 255, 255, 0.54);
		font-size: 1.2em;
	}
}

.usage-panel {

}

.pricing-panel {
	grid-column: 1 / -1;

	&.hide-registry {
		grid-column: unset;
	}

	.sub {
		display: grid;
		grid-template-columns: repeat(2, auto);
		grid-gap: 15px;
		justify-content: space-between;

		@media screen and (min-width: 900px) {
			grid-template-columns: repeat(4, auto);
		}

		.stat-sub {
			font-size: 1.2em;
			text-align: right;
		}

		.sub-label {
			color: primary;
			font-size: 0.8em;
		}
	}
}
</style>