<template>
	<div class="host-benchmark">
		<div :class="uploadClass">
			<icon :icon="['fad', uploadTachIcon]" />
			<div class="speed" v-html="uploadSpeedStr" />
			<h3>Upload</h3>
		</div>
		<div :class="downloadClass">
			<icon :icon="['fad', downloadTachIcon]" />
			<div class="speed" v-html="downloadSpeedStr" />
			<h3>Download</h3>
		</div>
	</div>
</template>

<script>
import { formatBitSpeed } from '@/utils/format';

export default {
	props: {
		benchmark: Object,
		average: Object
	},
	computed: {
		uploadSpeed() {
			const dataSize = this.benchmark && this.benchmark.data_size ? this.benchmark.data_size : 0,
				time = this.benchmark && this.benchmark.upload_time ? this.benchmark.upload_time / 1000 : 1;

			return dataSize * 8 / time;
		},
		downloadSpeed() {
			const dataSize = this.benchmark && this.benchmark.data_size ? this.benchmark.data_size : 0,
				time = this.benchmark && this.benchmark.download_time ? this.benchmark.download_time / 1000 : 1;

			return dataSize * 8 / time;
		},
		avgUploadSpeed() {
			const dataSize = this.average && this.average.data_size ? this.average.data_size : 0,
				time = this.average && this.average.upload_time ? this.average.upload_time / 1000 : 1;

			return dataSize * 8 / time;
		},
		avgDownloadSpeed() {
			const dataSize = this.average && this.average.data_size ? this.average.data_size : 0,
				time = this.average && this.average.download_time ? this.average.download_time / 1000 : 1;

			return dataSize * 8 / time;
		},
		uploadSpeedStr() {
			const format = formatBitSpeed(this.uploadSpeed, 'decimal', 2);

			return `${format.value} <span class="display-currency">${format.label}</span>`;
		},
		downloadSpeedStr() {
			const format = formatBitSpeed(this.downloadSpeed, 'decimal', 2);

			return `${format.value} <span class="display-currency">${format.label}</span>`;
		},
		uploadClass() {
			const delta = this.uploadSpeed / this.avgUploadSpeed,
				c = { upload: true };

			c[`upload-${this.normalizeSpeedDelta(delta)}`] = true;

			return c;
		},
		downloadClass() {
			const delta = this.downloadSpeed / this.avgDownloadSpeed,
				c = { download: true };

			c[`download-${this.normalizeSpeedDelta(delta)}`] = true;

			return c;
		},
		uploadTachIcon() {
			const delta = this.uploadSpeed / this.avgUploadSpeed;

			return `tachometer-alt-${this.normalizeSpeedDelta(delta)}`;
		},
		downloadTachIcon() {
			const delta = this.downloadSpeed / this.avgDownloadSpeed;

			return `tachometer-alt-${this.normalizeSpeedDelta(delta)}`;
		}
	},
	methods: {
		normalizeSpeedDelta(d) {
			if (d <= 0.5)
				return 'slowest';
			else if (d <= 0.8)
				return 'slow';
			else if (d <= 1 || d <= 1.5)
				return 'average';
			else if (d <= 2)
				return 'fast';

			return 'fastest';
		}
	}
};
</script>

<style lang="stylus" scoped>
.host-benchmark {
	display: grid;
	grid-gap: 15px;
	min-width: 200px;
	grid-template-columns: repeat(2, auto);
}

h3 {
	text-align: center;
	margin: 0 0 5px;
	color: primary;
	font-size: 0.8rem;
}

.upload, .download {
	svg {
		display: block;
		width: 64px;
		height: auto;
		margin: 0 auto 5px;
	}

	.fa-primary {
		fill: primary;
	}

	.fa-secondary {
		fill: dark-gray;
	}

	&.download-average .fa-primary, &.download-slow .fa-primary, &.upload-average .fa-primary, &.upload-slow .fa-primary {
		fill: warning-accent;
	}

	&.download-slowest .fa-primary, &.upload-slowest .fa-primary {
		fill: negative-accent;
	}
}

.speed, .average-speed {
	text-align: center;
}

.speed {
	font-size: 1.1rem;
	margin-bottom: 2px;
}

.average-speed {
	font-size: 0.8rem;
	color: rgba(255, 255, 255, 0.54);
}
</style>