<template>
	<div v-html="displayValue" />
	<div v-html="currencyValue" />
</template>

<script>
import { mapState } from 'vuex';
import BigNumber from 'bignumber.js';
import { formatPriceString } from '@/utils/format';

export default {
	props: {
		value: Object,
	},
	computed: {
		...mapState({
			currency: state => state.displayCurrency,
			exchangeRate: state => state.exchangeRate,
			network: state => state.network,
		}),
		baseCode() {
			return this.network === 'scprime' ? 'scp' : 'sc';
		},
		coinPrice() {
			return this.exchangeRate[this.baseCode] || {};
		},
		precision() {
			return this.network === 'scprime' ? new BigNumber('1e27') : new BigNumber('1e24');
		},
		currencyValue() {
			const { value, label } = formatPriceString(this.value, 2, this.currency, this.coinPrice[this.currency], this.precision, true);

			return `${value} <span class="currency-display">${label.toUpperCase()}</span>`;
		},
		displayValue() {
			const { value, label } = formatPriceString(this.value, 2, this.baseCode, 1, this.precision, true);

			return `${value} <span class="currency-display">${label.toUpperCase()}</span>`;
		}
	},
};
</script>