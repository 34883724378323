import { createRouter, createWebHistory } from 'vue-router';
import HostsList from '../views/HostsList.vue';

const routes = [
	{
		path: '/',
		name: 'default host',
		component: HostsList,
		props: () => ({
			network: 'sia',
		})
	},
	{
		path: '/sia',
		name: 'sia hosts',
		component: HostsList,
		props: () => ({
			network: 'sia',
		})
	},
	{
		path: '/zen',
		name: 'zen testnet hosts',
		component: HostsList,
		props: () => ({
			network: 'zen',
		})
	},
];

const router = createRouter({
	history: createWebHistory(process.env.BASE_URL),
	routes,
});

export default router;
