<template>
	<div class="page page-hosts" @scroll="onLoadMore" ref="page">
		<div class="page-content">
			<div class="network-stats">
				<network-stats :totals="stats.totals" :averages="stats.average" :network="network" />
			</div>
			<div class="filter">
				<host-filter :network="network" v-model:filter="filter" />
			</div>
			<div class="host-list">
				<div class="host-count" v-html="hostCount" />
				<host-item v-for="(host, index) in hosts" :key="host.public_key" :network="network" :protocol="filter.protocol || 'rhp3'" :host="host" :data-index="index" />
			</div>
			<transition :css="false" @enter="onButtonEnter" @leave="onButtonLeave" appear>
				<button v-if="scrolled" class="to-top" @click.prevent="onMoveToTop"><icon :icon="['fad', 'arrow-up']" /></button>
			</transition>
		</div>
	</div>
</template>

<script>
import { mapActions } from 'vuex';
import gsap, { Power4 } from 'gsap';
import { getSiaNetworkMetrics, getSiaHosts, getZenNetworkMetrics, getZenHosts } from '@/utils/api';
import { formatNumber } from '@/utils/format';
import HostItem from '@/components/HostItem.vue';
import HostFilter from '@/components/HostFilter.vue';
import NetworkStats from '@/components/NetworkStats.vue';

export default {
	components: {
		HostItem,
		HostFilter,
		NetworkStats,
	},
	props: {
		network: String
	},
	data() {
		return {
			hosts: [],
			stats: {},
			page: 0,
			matched: 0,
			total: 0,
			allLoaded: false,
			loading: false,
			scrolled: false,
			limit: 300,
			filter: {
				showinactive: false,
				sort: 'download_speed',
				dir: 'desc',
				protocol: 'rhp3',
			},
		};
	},
	computed: {
		hostCount() {
			return `Showing ${formatNumber(this.matched, 0)} / ${formatNumber(this.total, 0)} hosts`;
		},
	},
	beforeMount() {
		if (this.network === 'scprime')
			this.filter.protocol = 'rhp2';

		this.setNetwork(this.network);
		this.refreshData();
	},
	methods: {
		...mapActions([ 'setNetwork' ]),
		async loadMetrics() {
			try {
				const getMetrics = this.network === 'zen' ? getZenNetworkMetrics : getSiaNetworkMetrics,
					{ average, bottom, top, totals } = await getMetrics(this.filter.protocol);

				this.stats = { average, bottom, top, totals };
				this.total = totals.total_hosts;
			} catch (ex) {
				console.error('HostsList.loadMetrics', ex);
			}
		},
		async loadHosts(n, clear = false) {
			try {
				const getHosts = this.network === 'zen' ? getZenHosts : getSiaHosts,
					{ hosts, total } = await getHosts({
						...this.filter,
						page: n,
						limit: this.limit,
					});

				if (clear)
					this.hosts = [];

				this.hosts.push(...(hosts || []));

				this.allLoaded = !hosts || hosts.length === 0;
				this.page = n;
				this.matched = total;
			} catch (ex) {
				console.error('HostsList.loadHosts', ex);
			}
		},
		async refreshData() {
			this.allLoaded = false;
			this.page = 0;
			this.onMoveToTop();

			await Promise.allSettled([
				this.loadMetrics(),
				this.loadHosts(0, true),
			]);
		},
		async onLoadMore(e) {
			this.scrolled = (this.$refs?.page?.scrollTop || 0) > window.innerHeight / 5;

			if (this.loading || this.allLoaded)
				return;

			try {
				if (e.target.scrollTop / (e.target.scrollHeight - e.target.clientHeight) < 0.7)
					return;

				this.loading = true;

				await this.loadHosts(this.page + 1);
			} catch (ex) {
				console.error('HostsList.onLoadMore', ex);
			} finally {
				this.loading = false;
			}
		},
		onMoveToTop() {
			try {
				gsap.to(this.$refs.page, {
					scrollTop: 0,
					duration: 0.5,
				});
			} catch (ex) {
				console.error('HostsList.onMoveToTop', ex);
			}
		},
		onButtonEnter(el, done) {
			try {
				gsap.fromTo(el, {
					autoAlpha: 0,
					y: 30,
					scale: 1.5,
					duration: 0.5
				}, {
					autoAlpha: 1,
					y: 0,
					scale: 1,
					transformOrigin: '50% 50%',
					ease: Power4.easeOut,
					onComplete: done,
				});
			} catch (ex) {
				console.error('HostsList.onButtonEnter', ex);
			}
		},
		onButtonLeave(el, done) {
			try {
				gsap.to(el, {
					opacity: 0,
					y: 30,
					scale: 1.5,
					duration: 0.5,
					onComplete: done,
				});
			} catch (ex) {
				console.error('HostsList.onButtonLeave', ex);
			}
		},
	},
	watch: {
		filter() {
			console.log('loading new data', this.filter);
			this.hosts = [];
			this.refreshData();
		}
	}
};
</script>

<style lang="stylus" scoped>
.page-hosts .page-content {
	max-width: 1600px;
	margin: 0 auto;

	@media screen and (min-width: 767px) {
		display: grid;
		grid-template-columns: auto minmax(0, 1fr);
	}
}

.header {
	margin: 0;
	color: rgba(255, 255, 255, 0.54);
	text-align: center;
}

.network-stats, .header {
	grid-column: 1 / -1;
}

.network-stats {
	display: grid;
	grid-gap: 15px;
	padding: 15px;
	align-items: center;

	@media screen and (min-width: 900px) {
		grid-template-columns: repeat(3, minmax(0, 1fr));

		&.hide-registry {
			grid-template-columns: repeat(2, minmax(200px, min-content)) minmax(0, 1fr);
		}
	}
}

.host-list, .filter {
	padding: 15px;
}

.host-count {
	margin-bottom: 15px;
	text-align: right;
	color: rgba(255, 255, 255, 0.54);
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
}

.to-top {
	position: fixed;
	width: 48px;
	height: 48px;
	padding: 0;
	margin: 0;
	bottom: 80px;
	right: 40px;
	border-radius: 100%;
	border: none;
	background: bg-dark-accent;
	box-shadow: 1px 2px 5px 1px rgba(0, 0, 0, 0.84);
	color: rgba(255, 255, 255, 0.54);
	cursor: pointer;
	outline: none;

	@media screen and (min-width: 767px) {
		bottom: 10%;
		right: 10%;
	}
}
</style>